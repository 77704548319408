<template>
  <div class="index_wrap" >
    <img src="./img/logo.png" class="logo_img" />
    <div class="tabs_wrap" >
      <div class="tabs_item"
           :class="{ 'tabs_active': current == 0 }"
           @click="tabsChange(0)"
      >
        登录
        <div class="tabs_line" v-if="current == 0" ></div>
      </div>
      <div class="tabs_item"
           :class="{ 'tabs_active': current == 1 }"
           @click="tabsChange(1)"
      >
        注册
        <div class="tabs_line" v-if="current == 1" ></div>
      </div>
    </div>

    <div class="form_wrap" v-if="current == 0" >
      <div class="form_item" >
        <div class="form_input_wrap" >
          <img src="./img/1.png" class="input_icon" />
          <input placeholder="请输入手机号" class="form_input" v-model="loginForm.phone" type="number" />
        </div>

      </div>

      <div class="form_item" >
        <div class="form_input_wrap" >
          <img src="./img/3.png" class="input_icon" />
          <input placeholder="请输入密码" class="form_input" v-model="loginForm.password" type="password" />
        </div>
      </div>

      <van-button class="register_button" :loading="submitting" @click="handleLogin" >登录</van-button>
      <div class="forget_pwd" @click="toPage('forget')" >忘记密码？</div>
    </div>

    <div class="form_wrap" v-if="current == 1" >
      <div class="form_item" >
        <div class="form_input_wrap" >
          <img src="./img/4.png" class="input_icon" />
          <input placeholder="请输入姓名" class="form_input" v-model="registerForm.name" />
        </div>
      </div>

      <div class="form_item" >
        <div class="form_input_wrap" >
          <img src="./img/1.png" class="input_icon" />
          <input placeholder="请输入手机号" class="form_input" v-model="registerForm.phone" type="number" />
        </div>
      </div>

      <div class="form_item" >
        <div class="form_input_wrap w-195" >
          <img src="./img/2.png" class="input_icon" />
          <input placeholder="请输入验证码" class="form_input" v-model="registerForm.verificationCode" :maxlength="6" type="number" />
        </div>
        <div class="get_code" v-if="second <= 0" @click="getCode" >
          获取验证码
        </div>
        <div class="get_code" v-else style="font-size: 15px; opacity: 0.6">
          {{ second }}秒后重新获取
        </div>
      </div>

      <div class="form_item" >
        <div class="form_input_wrap" >
          <img src="./img/3.png" class="input_icon" />
          <input placeholder="请输入密码" class="form_input" v-model="registerForm.password" type="password" />
        </div>
      </div>

      <div class="form_item" >
        <div class="form_input_wrap" >
          <img src="./img/3.png" class="input_icon" />
          <input placeholder="请再次输入密码" class="form_input" v-model="registerForm.repeatPassword" type="password" />
        </div>
      </div>
      <van-button class="register_button" :loading="submitting" @click="handleRegister" >注册</van-button>
    </div>

    <div class="welcome_text" >欢迎来到康域</div>

  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>

import {
  sendSelfVerificationCode,
  reg,
  login
} from '@/api'

import {
  Toast
} from 'vant'

import {
  handleAuth
} from "@/utils/auth";

export default {
  name: 'register',
  data() {
    return {
      current: 0,
      loginForm: {
        password: '',
        phone: '',
        code: ''
      },
      registerForm: {
        name: '',
        password: '',
        phone: '',
        verificationCode: '',
        repeatPassword: '',
      },
      submitting: false,
      code: '',
      second: 0,
      timer: null,
      sn: this.$route.query.sn
    }
  },
  methods: {
    toPage(name) {
      this.$router.push({
        name
      })
    },
    handleLogin() {
      if(!this.loginForm.phone) {
        Toast('请输入手机号')
        return false
      }

      if(!this.isPhone(this.loginForm.phone)) {
        Toast('手机号格式不正确')
        return false
      }

      if(!this.loginForm.password) {
        Toast('请输入密码')
        return false
      }

      this.submitting = true
      login({...this.loginForm}).then(res => {
        this.submitting = false
        if(res.success) {
          console.log('token:', res.data.token)
          localStorage.setItem('token', res.data.token)
          window.location.reload()
        }else {
          Toast(res.msg)
        }
      })
    },
    getCode() {
      if(!this.registerForm.phone) {
        Toast('请输入手机号')
        return false
      }

      if(!this.isPhone(this.registerForm.phone)) {
        Toast('手机号格式不正确')
        return false
      }

      sendSelfVerificationCode({ phone: this.registerForm.phone }).then(res => {
        if(res.success) {
          this.second = 60
          this.timer = setInterval(() => {
            this.second --
            if(this.second <= 0) {
              clearInterval(this.timer)
            }
          }, 1000)
        }else {
          Toast(res.msg)
        }
      })
    },
    isPhone(str) {
      let flag = true
      let reg = /^1\d{10}$/
      if(!reg.test(str)) {
        flag = false
      }
      return flag
    },
    handleRegister() {
      if(!this.registerForm.name) {
        Toast('请输入姓名')
         return false
      }

      if(!this.registerForm.phone) {
        Toast('请输入手机号')
        return false
      }

      if(!this.isPhone(this.registerForm.phone)) {
        Toast('手机号格式不正确')
        return false
      }

      if(!this.registerForm.verificationCode) {
        Toast('请输入验证码')
        return false
      }

      if(!this.registerForm.password) {
        Toast('请输入密码')
        return false
      }

      if(!this.registerForm.repeatPassword) {
        Toast('请输入确认密码')
        return false
      }

      if(this.registerForm.repeatPassword != this.registerForm.password) {
        Toast('两次密码输入不一致')
        return false
      }

      let values = JSON.parse(JSON.stringify(this.registerForm))
      delete values['repeatPassword']

      this.submitting = true

      reg({...values}).then(res => {
        this.submitting = false
        if(res.success) {
          Toast('注册成功，请进行登录')
          this.tabsChange(0)
        }else {
          Toast(res.msg)
        }
      })
    },
    tabsChange(current) {
      clearInterval(this.timer)
      console.log('code:', this.code)
      this.second = 0
      this.current = current
      switch (current) {
        case 0:
          document.title = '登录'
          break
        case 1:
          document.title = '注册'
          break
      }
      this.initForm(current)
    },
    initForm(current) {
      switch (current) {
        case 0:
          this.loginForm.password = ''
          this.loginForm.phone = ''
          break
        case 1:
          this.registerForm = {
            name: '',
            password: '',
            phone: '',
            verificationCode: '',
            repeatPassword: '',
          }
          break
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    let code = this.$route.query.code
    console.log('code:', code)
    this.loginForm.code = code
    if(!code) {
      handleAuth(false)
    }
  }
}
</script>
